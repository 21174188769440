import React from 'react';
import logo from './logo.png';
import './App.css';
import './fonts.css';

function App() {
  return (
      <div className="App">
          <div className="App-header">
              <div className="App-letter">
                  <h1 className="letterMark">A</h1>
              </div>
              <div className="App-define">
                  <h1 className="meaningHeading">Aeternum</h1>
                  <p className="meaningSubHeading">Latin | (ɪn iːˈtɜːnəm)</p>
                  <p className="meaningText">(adverb) forever; eternally</p>
                  <hr className="solid"/>
                  <a href="#theOverview" className="navLink">overview </a>
                  <span className="navLink"> | </span>
                  <a href="#theGoal" className="navLink">goal</a>
                  <span className="navLink"> | </span>
                  <a href="#contactEmail" className="navLink">contact</a>
                  <hr className="solid"/>
              </div>
          </div>
          <div className="App-content">
              <div className="App-text">
                  <h2 id="theOverview" className="contentHeading">Overview of Aeternum</h2>
                  <p className="contentText">
                      At Aeternum, we're building the future of consumer AI. We craft innovative products that make
                      complex tasks easier, information more accessible, and lifelong learning exhilarating.
                      Whether you're navigating markets, exploring new skills, or seeking creative inspiration, our AI
                      solutions put the transformative power of technology into your hands.
                      With Aeternum products, achieving your goals becomes an exciting journey.
                  </p>

                  <h3 id="theGoal" className="contentHeading">Aeternum's Goal</h3>
                  <p className="contentText">
                      Revolutionise interactions with AI technology, designing empowering transformative AI experiences
                      that unlock lasting value for consumers.
                  </p>
              </div>
          </div>
          <div className="App-footer">
              <div className="App-text">
                  <h4 id="contactEmail">Contact</h4>
                  <p >discover@aeternumai.com</p>
              </div>
          </div>
      </div>
  );
}

export default App;
